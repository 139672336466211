import React, { createContext, useState, useEffect, useCallback } from "react";
import { JobTypesGetList } from '../../api/jobTypes';

export const Context = createContext({});

export const Provider = ({children}) => {
  const [jobTypes, setJobTypes] = useState([]);

  const LoadData = useCallback(async() => {
    var jobTypesResult = await JobTypesGetList();
    setJobTypes(jobTypesResult);
  }, []);

  // Make the context object:
  const usersContext = {
    jobTypes,
  };

  useEffect(() => {
    LoadData();
  }, [LoadData])

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;
