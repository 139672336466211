import axios from 'axios';

export const JobTypesGetList = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: '/v1/JobTypes',
      params: {},
    });

    return result.data;
  } catch (err) {
    console.error(err);
  }
};
